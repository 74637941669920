<template>
      <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
            <div class="deal-task-tracker" >
            <div class="card-table-body ifon-add-title-block">
                <el-row>
                    <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                        <div class="content-in__title filter-title">
                            <h1 class="content-title mr-3" :class="mode ? 'content__titleday' : 'content__titlenight'">{{$t('message.sender_warehouse')}}</h1>
                            <div class="block-sarche">
                                <div class="header__search">
                                    <div class="filter_filial">
                                        <select-from-filial
                                            :size="'medium'"
                                            :select_only="false"
                                            :class="mode ? 'filter__day' : 'filter__night'"
                                            :placeholder="columns.from_filial.title"
                                            v-model="filterForm.from_filial_id"
                                        >
                                        </select-from-filial>
                                        <select-to-filial
                                            :size="'medium'"
                                            :select_only="false"
                                            :class="mode ? 'filter__day' : 'filter__night'"
                                            :placeholder="columns.to_filial.title"
                                            v-model="filterForm.to_filial_id"
                                        >
                                        </select-to-filial>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                        <div class="content__setting">
                            <div v-show="activeTabName === 'products'" class="custom__button ml-3" >
                                <el-button @click="showIncomingHistory()" size="medium" :type="showHistory ? 'primary' : 'info'">
                                    {{ showHistory ? $t('message.active') : $t('message.history') }}
                                </el-button>
                            </div>

                            <div class="custom__button ml-3" >
                                <el-button @click="collectPackage()" size="medium" type='primary'>
                                    {{ $t('message.collect_packaging') }}
                                </el-button>
                            </div>
                            <div class="custom__button ml-3" @click="newCargoDrawer = true" v-if="permissions.some(per => per.slug == 'productCirculations.create')">
                                <el-button type="primary" icon="el-icon-s-order" size="medium" >
                                    {{ $t('message.add') }}
                                </el-button>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <div class="card-table-header table-crm-smart custom__scroll">
                <el-tabs v-model="activeTabName" @tab-click="handleTabClick" type="card">
                    <el-tab-pane name="packages">
                        <span slot="label" :class="activeTabName !== 'packages' ? (mode ? '' : 'text-white') : ''"> {{ $t('message.packages') }} </span>
                        <packages-table ref="packagesTable" :parentFilter="filterForm" v-if="activatedTabs.includes('packages')"></packages-table>
                    </el-tab-pane>
                    <el-tab-pane name="products">
                        <span slot="label" :class="activeTabName !== 'products' ? (mode ? '' : 'text-white') : ''"> {{ $t('message.products') }} </span>
                        <products-table ref="productsTable" :parentFilter="filterForm" v-if="activatedTabs.includes('products')"></products-table>
                    </el-tab-pane>
                    <!-- <el-tab-pane name="parcels">
                        <span slot="label" :class="activeTabName !== 'parcels' ? (mode ? '' : 'text-white') : ''"> {{ $t('message.parcels') }} </span>
                        <parcels-table ref="parcelsTable" :parentFilter="filterForm" v-if="activatedTabs.includes('parcels')"></parcels-table>
                    </el-tab-pane> -->
                </el-tabs>
            </div>
    
            <el-drawer class="bg-se" :with-header="false"
                :visible.sync="packageDrawer"
                ref="packageDrawer"
                @opened="drawerOpened('packageDrawerChild')"
                @closed="drawerClosed('packageDrawerChild')" 
                size="90%">
            
                <crm-package
                    ref="packageDrawerChild"
                    drawer="packageDrawer"
                ></crm-package>

            </el-drawer>

            <el-drawer class="bg-se" :with-header="false"
                :visible.sync="newCargoDrawer"
                ref="newCargoDrawer"
                @opened="drawerOpened('newCargoDrawerChild')"
                @closed="drawerClosed('newCargoDrawerChild')" 
                size="100%">
                <AppNewCargo 
                    :selectedItem="selectedItem"
                    ref="newCargoDrawerChild"
                    drawer="newCargoDrawer"
                    @askBarcodePrinting="dialogPrintBarcode = true"
                />
            </el-drawer>

            <el-dialog 
                :title="$t('message.do you want to print barcodes')" 
                :append-to-body="true"
                :visible.sync="dialogPrintBarcode" 
                width="30%" 
                @opened="$refs['printBarcodes'] ? $refs['printBarcodes'].afterOpen() : ''">
                    <div class="w-100">
                        <el-button class="w-100 d-block ml-2 mb-3" type="primary" @click="printGivenBarcodes()">{{ $t('message.barcode_with_address') }}</el-button>
                        <el-button class="w-100 d-block mb-3" type="primary" @click="printGivenBarcodes('without_address')">{{ $t('message.barcode_without_address') }}</el-button>
                        <el-button class="w-100 d-block" @click="dialogPrintBarcode = false">{{ $t('message.close') }}</el-button>
                    </div>
            </el-dialog>

        </div>
    </div>

</template>

<script>
import AppNewCargo from './components/app-new-cargo.vue'
import { mapGetters, mapActions } from "vuex";
import list from "@/utils/mixins/list";
import form from "@/utils/mixins/form";
import drawer from "@/utils/mixins/drawer";
import CrmPackage from "./components/crm-package";
import productsTable from "./components/products-table";
import packagesTable from "./components/packages-table";
import parcelsTable from "./components/parcels-table";
import selectFromFilial from "@/components/selects/select-from-filial";
import selectToFilial from "@/components/selects/select-to-filial";

export default {
    name: "tasks",
    mixins: [list, form, drawer],
    components: {
        AppNewCargo,
        CrmPackage,
        selectFromFilial,
        selectToFilial,
        productsTable,
        packagesTable,
        parcelsTable
    },
    
    data() {
        return {
            dialogPrintBarcode: false,
            showHistory: false,
            packageDrawer: false,
            newCargoDrawer: false,
            loadingButton: false,
            activeTabName: 'packages',
            activatedTabs: ['packages'],
            form: {
                id: null,
                incoming_quantity: 0
            }
        };
    },
    computed: {
        ...mapGetters({
            list: "productCirculations/list",
            barcodesForPrinting: "productCirculations/barcodesForPrinting",
            packageList: "packageForProductCirculations/list",
            permissions: "auth/permissions",
            columns: "productCirculations/columns",
            pagination: "productCirculations/pagination",            
            filter: "productCirculations/filter",
            sort: "productCirculations/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "productCirculations/index",
            printReceivedBarcodes: "filials/printReceivedBarcodes",
            updatePackageList: "packageForProductCirculations/index",
            setPagination: "productCirculations/setPagination",
            updateSort: "productCirculations/updateSort",
            updateFilter: "productCirculations/updateFilter",
            updateColumn: "productCirculations/updateColumn",
            updatePagination: "productCirculations/updatePagination",
            show: "productCirculations/show",
            updateEditedRow: "productCirculations/updateEditedRow",
            update: "productCirculations/update",
            empty: "productCirculations/empty",
            delete: "productCirculations/destroy",
            refreshData: "productCirculations/refreshData",
            printProductBarcode: "productCirculations/printProductBarcode",
        }),
        printGivenBarcodes(type = null) {
            console.log('this.barcodesForPrinting, type');
            console.log(this.barcodesForPrinting, type);
            this.printReceivedBarcodes({barcodes: this.barcodesForPrinting, type: type})
                .then(res => {
                    const WinPrint = window.open("", "", "left=0,top=0,toolbar=0,scrollbars=0,status=0");
                    WinPrint.document.write(res.data);
                    WinPrint.document.close();
                    WinPrint.focus();
                    setTimeout(() => {
                        WinPrint.print();
                        WinPrint.close();
                    }, 1000);
                    this.dialogPrintBarcode = false;
                }).catch(err => {
                    this.$alert(err);
                });
        },
        collectPackage() {
            this.packageDrawer = true;
        },
        handleTabClick(tab, event){
            if(this.activatedTabs.includes(tab.name)){
                this.fetchData();
            }
            if(!this.activatedTabs.includes(tab.name)){
                this.activatedTabs.push(tab.name);
            }
        },

        async fetchData() {
            if(this.activeTabName === 'packages' && this.$refs.packagesTable && _.isFunction(this.$refs.packagesTable.debouncedFetchData)){
                this.$refs.packagesTable.debouncedFetchData();
            }
            if(this.activeTabName === 'products' && this.$refs.productsTable && _.isFunction(this.$refs.productsTable.debouncedFetchData)){
                this.$refs.productsTable.debouncedFetchData();
            }
            if(this.activeTabName === 'parcels' && this.$refs.parcelsTable && _.isFunction(this.$refs.parcelsTable.debouncedFetchData)){
                this.$refs.parcelsTable.debouncedFetchData();
            }
        },
        changeFilials() {
            if(this.filterForm.from_filial_id && this.filterForm.to_filial_id){
                let from = JSON.parse(JSON.stringify(this.filterForm.from_filial_id));
                let to = JSON.parse(JSON.stringify(this.filterForm.to_filial_id));
                this.$set(this.filterForm, 'from_filial_id', to);
                this.$set(this.filterForm, 'to_filial_id', from);
            }
        },

        showIncomingHistory(){
            this.showHistory = !this.showHistory;
            this.$set(this.filterForm, 'showHistory', this.showHistory)
        },

        printBarCode(payload) {
            this.printProductBarcode(payload)
            .then(res => {
                const WinPrint = window.open("", "", "left=0,top=0,toolbar=0,scrollbars=0,status=0");
                WinPrint.document.write(res.data);
                WinPrint.document.close();
                WinPrint.focus();
                setTimeout(() => {
                    WinPrint.print();
                    WinPrint.close();
                }, 1000);
            })
            .catch(err => {
                this.$alert(err);
            });
        },
        updateIncoming(){
            this.loadingButton = true;
            this.update(this.form).then(res => {
                if(res.data.result.success){
                    this.updateEditedRow(this.form);
                    this.$alert(res);
                    this.cancelUpdate();
                }else{
                    this.cancelUpdate();
                    this.$error_message(res);
                }
                this.loadingButton = false;
            }).catch(err => {
                this.loadingButton = false;
            });
        },
        cancelUpdate(){
            this.$set(this.form, 'id', null);
            this.$set(this.form, 'incoming_quantity', 0);
        },
        handleCommand({action, model}) {
            if (action === "barcode") {
                this.printBarCode({barcode: model.barcode, deal_id: model.deal_id, date: model.date});
            }
            if (action === "edit") {
                this.$set(this.form, 'id', model.id);
                this.$set(this.form, 'incoming_quantity', parseFloat(model.incoming_quantity));
            }

            if (action === "return") {

            }
        }, 
    
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('productCirculations/EMPTY_LIST');
        next()
    },
};
</script>

<style>
.coloa3 {
   background: #ff3f3f;
}
.container__tasks {
    width: 100% ;
    margin: 0 !important;
}
.test__width {
    width: 100% !important;
}
.button_no_padding {
    padding: 7px 10px !important;
}
.filter_filial{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 500px;
    min-height: 20px;
}
</style>
